@import "./fonts.scss";

body {
  height: 100vh;

  padding: 0;
  margin: 0;

  background: linear-gradient(180deg, #0a0e16 0%, #1c3360 100%);
  background-repeat: no-repeat;
  color: #9d9fa3;
  overflow: hidden;

  font-family: "Inter", sans-serif;
}

audio {
  display: none;
}
