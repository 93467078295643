.talker {
  width: 70%;
  height: 80%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  border-radius: 32px;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px 32px 32px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 16px;
  }
}

.logo {
  @media (max-width: 767px) {
   display: none;
  }
}

.videosContainer {
  position: relative;
  width: 100%;
  display: flex;
  max-height: 70%;
  justify-content: center;
  @media (max-width: 767px) {
    height: 100%;
    max-height: 100%;
  }

  video {
    border-radius: 4px;
  }

  .remoteVideo {
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 767px) {
      width: 100%;
      margin: auto;
    }
  }

  .localVideo {
    position: absolute;
    max-width: 200px;
    max-height: 200px;
    right: 8px;
    top: 8px;
  }
}

.actions {
  bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 32px;
  @media (max-width: 767px) {
    position: fixed;
  }

  .action {
    height: 48px;
    width: 48px;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.13);
    }

    svg {
      width: 30px;
      height: 30px;
      fill: #fff
    }
  }

  .hangup {
    background-color: rgba(255, 40, 40, 0.5)
  }
}