@font-face {
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;

  src: url("assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: bold;
  font-style: normal;

  src: url("assets/fonts/Inter-SemiBold.ttf") format("truetype");
}
